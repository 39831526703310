<!--
<img width="100%" src="https://emotd.mx/menuChepe/1.jpeg">
<img width="100%" src="https://emotd.mx/menuChepe/2.jpeg">
<img width="100%" src="https://emotd.mx/menuChepe/3.jpeg">
<img width="100%" src="https://emotd.mx/menuChepe/4.jpeg">
<img width="100%" src="https://emotd.mx/menuChepe/postres.jpeg">

-->
<div class="pdf-container">
  <pdf-viewer [src]="src"
              [original-size]="false"
  ></pdf-viewer>
</div>