import { Component, OnInit } from '@angular/core';

import { DatabaseService } from "../../services/database.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  listaMenu: any[] = [];
  listaCategorias: any[] = [];

  constructor(
    private bd: DatabaseService,
  ) { }

  ngOnInit(): void {
    let idR = 'NqufQezu7E0qD1MXkPYB';
    this.bd.getMenuCategoriaRestaurante(idR).subscribe((c: any[]) => {
      this.listaCategorias = c;
      console.log(c);
      
      this.bd.getMenuRestaurante(idR).subscribe((m: any[]) => {
        this.listaMenu = m;
        console.log(m);
        
      });
    });
  }

}
