import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  urlAPI = "https://quinielonmx.com/apis/dfoodColAPI/";
  constructor(
    private http: HttpClient,
  ) { }


  getMenuRestaurante(idRestaurante: string) {
    const respuestaAPI = `${this.urlAPI}getMenuRestauranteAdmin/${idRestaurante}`;
    return this.http.get(respuestaAPI);
  }

  getCategoriasRestaurante(idR: string) {
    const respuestaAPI = `${this.urlAPI}categoriaRGET/${idR}`;
    return this.http.get(respuestaAPI);
  }

  getMenuRestauranteLibre(idRestaurante: string) {
    const respuestaAPI = `${this.urlAPI}/getMenuRestaurante/${idRestaurante}`;
    return this.http.get(respuestaAPI);
  }
  getMenuCategoriaCarniceria(idRestaurante: string) {
    const respuestaAPI = `${this.urlAPI}/menuCategoriaCarniceria/${idRestaurante}`;
    return this.http.get(respuestaAPI);
  }

  getMenuCategoriaRestaurante(idRestaurante: string) {
    const respuestaAPI = `${this.urlAPI}/menuCategoriaRestaurante/${idRestaurante}`;
    return this.http.get(respuestaAPI);
  }

  gerURL(){
    //const respuestaAPI = `https://emotd.herokuapp.com/urlqr`;
    const respuestaAPI = `https://apicolimatogo.emotd.com.mx/urlqr`;
    return this.http.get(respuestaAPI);
  }
}
