import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImagenesComponent } from './pages/imagenes/imagenes.component';
import { MenuComponent } from './pages/menu/menu.component';
import { CargarComponent } from './pages/cargar/cargar.component';


const routes: Routes = [
  { path: '', component: ImagenesComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'carga', component: CargarComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
