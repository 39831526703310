import { Compiler, Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.css']
})
export class ImagenesComponent implements OnInit {
  pdfSrc = "https://emotd.mx/menuChepe/1.pdf";
  src = "";
  constructor(
    private _compiler: Compiler,
    private bd: DatabaseService
  ) { }

  ngOnInit(): void {
    console.log(1);
    
    this.bd.gerURL().subscribe((r: any) => {
      console.log(r);
      window.location.href=r.url;
      this.src = r.url;
    });
  }

}
