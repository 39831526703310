import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImagenesComponent } from './pages/imagenes/imagenes.component';
import { MenuComponent } from './pages/menu/menu.component';

import { HttpClientModule } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CargarComponent } from './pages/cargar/cargar.component';


@NgModule({
  declarations: [
    AppComponent,
    ImagenesComponent,
    MenuComponent,
    CargarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PdfViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
