<div id="wrapper">
    <div class="">
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <h4 class="page-title">Tabs & Accordions</h4>
                </div>
                <div class="row">
                    <div class="col-lg-12" *ngFor="let categoria of listaCategorias">
                        <div class="card-box">
                            <h4 class="header-title">{{categoria.nombreCategoria}}</h4>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-xl-2" *ngFor="let producto of categoria.productoCategoria">
                                <div class="card m-b-30">
                                    <div class="card-body">
                                        <h5 class="card-title">{{producto.nombreProducto}}</h5>
                                        <h6 class="card-subtitle text-muted">
                                            <b>${{producto.productoPrecio | number: '1.2-2'}}</b></h6>
                                    </div>
                                    <img class="img-fluid" [src]="producto.imagenProducto"
                                        [alt]="producto.nombreProducto" width="100%" height="280">
                                    <div class="card-body">
                                        <p class="card-text">{{producto.productoDescripcion}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>